export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '社会信用代码',
    key: 'credit_code',
    minWidth: 105
  },
  {
    title: '法人代表',
    key: 'legal',
    width: 100
  },
  {
    title: '电话',
    key: 'telephone',
    width: 140
  },
  {
    title: '经营地址',
    key: 'address',
    minWidth: 150
  },
  {
    title: '二维码',
    key: 'code_url',
    // fixed: 'right',
    // width: 150,
    scopedSlots: {
      customRender: 'code_url'
    }
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 150,
    // extra: [{ label: '详情', name: 'edit' }]
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const rules = {
  site_id: [{ required: true, message: '请输入加油站名称' }],
  tanker_id: [{ required: true, message: '请输入加油机名称' }],
  gun_num: [{ required: true, message: '请输入加油枪编号' }],
  type: [{ required: true, message: '请输入抽检油品' }],
  quantity: [
    { required: true, message: '请输入抽检油量' },
    {
      pattern: /^[0-9]{0,8}([.]{1}[0-9]{0,2}){0,1}$/,
      message: '格式不正确, 最多输入整数八位并且最多保留小数后二位'
    }
  ],
  time: [{ required: true, message: '请输入抽检时间' }],
  status: [{ required: true, message: '请输入抽检状态' }]
};
