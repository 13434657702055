<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item label="加油站名称">
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="请输入"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="法人代表">
          <el-input
            v-model="searchParams.legal"
            clearable
            placeholder="法人代表"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
      <!--  -->
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #code_url="{ scope }">
        <el-popover placement="top" trigger="hover">
          <vue-qr
            slot="reference"
            :logoSrc="imageUrl"
            :text="
              `${localhost}/PhoneComplaint?site_id=${scope.row.site_id}&site_name=${scope.row.site_name}`
            "
            :size="60"
            :margin="0"
          ></vue-qr>

          <div style="text-align: center">
            <vue-qr
              slot="reference"
              :logoSrc="imageUrl"
              :text="
                `${localhost}/PhoneComplaint?site_id=${
                  scope.row.site_id
                }&site_name=${encodeURIComponent(scope.row.site_name)}`
              "
              :size="150"
              :ref="`qr_${scope.row.site_id}`"
              :margin="0"
            ></vue-qr>
            <!-- {{ `` }} -->
            <div>{{ scope.row.site_name }}</div>
            <div style="margin-top: 10px;">
              <el-button
                type="primary"
                size="small"
                @click="downloadQR(scope.row)"
                >下载</el-button
              >
            </div>
          </div>
        </el-popover>
      </template>
      <template #actions="{ scope }">
        <el-button type="primary" size="small" @click="downloadQR(scope.row)"
          >下载二维码</el-button
        >
      </template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import vueQr from 'vue-qr';
import { getSiteCode, ExportGasCode } from '@/api/qr_code';

import mixins from '@/mixins';

import { columns } from './columns/column';

export default {
  components: {
    PageHeader,
    DefaultTable,
    vueQr
  },
  data() {
    return {
      tableLoading: true,
      searchParams: {},
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 8
      },
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: require('@/assets/logo.png'),
      localhost: ''
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: this.pagination.pageSize
    });

    this.localhost = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      this.localhost += `:${window.location.port}`;
    }
    console.log(this.localhost);
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || this.pagination.pageSize,
        ...this.searchParams
      };
      getSiteCode(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: this.pagination.pageSize
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportGasCode(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    // 下载图片
    downloadQR(row) {
      let a = document.createElement('a');
      a.download = `${row.site_name}.png`;
      a.href = this.$refs[`qr_${row.site_id}`].$el.src;
      // a.href = this.$refs.qr.$el.src;
      a.dispatchEvent(new MouseEvent('click'));
    }
  }
};
</script>
<style></style>
